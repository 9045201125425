import './App.css'
import {HashRouter, Route, Routes} from 'react-router-dom';
import Home from "./pages/Home";
import Management from "./pages/Management/Management";
import Header from "./layout/Header";
import Login from "./pages/Login";
import {
    isAnonymous,
    isAuthenticatedUser,
    isAuthenticationNeededException,
    isUserError,
    useCurrentUserOrError
} from "./data/user";
import LoginWithUserAndPasswordComponent from "./pages/Login/LoginWithUserAndPasswordComponent";
import Chat from "./pages/Chat/Chat";
import TransactionHistory from "./pages/TransactionHistory/TransactionHistory";
import ManagementUsers from "./pages/Management/users/ManagementUsers";
import ManagementHotels from "./pages/Management/hotels/ManagementHotels";
import React, {useContext} from "react";
import {GlobalContext, GlobalContextProvider} from "./data/GlobalContext";
import {Toast} from "primereact/toast";
import {Role} from "./data/BackendClasses";
import NotAllowed from "./pages/NotAllowed";
import Supervising from "./pages/Logs/Supervising/Supervising";
import Dropdown from "./pages/Logs/DropDown/DropDownPage";
import InternalLogsPage from "./pages/Logs/InternalLogs/InternalLogsPage";
import InternalNotesPage from "./pages/Logs/InternalNotes/InternalNotesPage";
import LowInventoryScanner from "./pages/LowInventoryScanner/LowInventoryScanner";
import HotelsSpreadsheetPage from "./pages/Siteminder/HotelsSpreadsheet/HotelsSpreadsheetPage";
import SiteminderHistoryPage from "./pages/Siteminder/SIteminderHistory/SiteminderHistoryPage";
import LogsPage from "./pages/Logs/LogsPage";
import SiteminderPage from "./pages/Siteminder/SiteminderPage";
import SiteminderReceiverPage from "./pages/Siteminder/SiteminderReceiver/SiteminderReceiverPage";
import CustomSidebar from "./layout/CustomSidebar";
import TransactionLogsPage from "./pages/Logs/TransactionLogs/TransactionLogsPage";
import ReviewsHotelPage from "./pages/Logs/ReviewsHotel/ReviewsHotelPage";
import CancellationPage from "./pages/Cancellation/CancellationPage";
import SiteminderBookingCancellationPage
    from "./pages/Siteminder/SiteminderBookingCancellation/SiteminderBookingCancellationPage";
import SiteminderReceiverHistoryPage from "./pages/Siteminder/SiteminderReceiverHistory/SiteminderReceiverHistoryPage";
import ReviewsHotelPublicPage from "./pages/Logs/ReviewsHotelPublic/ReviewsHotelPublicPage";
import CloudbedsCalendarPage from "./pages/Cloudbeds/Calendar/CloudbedsCalendarPage";
import CloudbedsBookingsPage from "./pages/Cloudbeds/Bookings/CloudbedsBookingsPage";
import ModifyCalendarLogsPage from "./pages/Cloudbeds/ModifyCalendarLogs/ModifyCalendarLogsPage";
import DerbysoftHistoryPage from "./pages/Derbysoft/History/DerbysoftHistory";
import DerbysoftReceiverPage from "./pages/Derbysoft/Receiver/DerbysoftReceiverPage";
import DerbysoftReceiverHistoryPage from "./pages/Derbysoft/ReceiverHistory/DerbysoftReceiverHistoryPage";
import DerbysoftHotelsSpreadsheetPage from "./pages/Derbysoft/HotelSpreadsheet/DerbysoftHotelsSpreadsheetPage";
import CsvLoaderPage from "./pages/Logs/CsvLoader/CsvLoaderPage";
import ExtractBasePricePage from "./pages/Logs/ExtractBasePrice/ExtractBasePrice";
import BankingSecurityCodePage from "./pages/BankingSecurityCode/BankingSecurityCodePage";

const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

function AppInner() {
    const userOrError = useCurrentUserOrError();

    const {toastRef, sseLoginEvent, sidebarVisible, setSidebarVisible} = useContext(GlobalContext);

    if(isUserError(userOrError)){
        return <Login>
            <div>{userOrError.message}</div>
        </Login>
    }

    if(isAuthenticationNeededException(userOrError)){
        return <Login>
            <LoginWithUserAndPasswordComponent/>
            <Toast ref={toastRef} position={"bottom-right"}/>
        </Login>
    }

    if(isAnonymous(userOrError)){
        return <Login>
            <div className="fa-3x">
                <i className="fa fa-circle-notch fa-spin"/>
            </div>
        </Login>
    }

    function userHasRole(role: Role){
        return isAuthenticatedUser(userOrError) && userOrError.roles.includes(role);
    }

    return <div className="flex flex-col h-screen w-full">

            <HashRouter>
                <Header/>
                <div className="grow overflow-y-auto">
                    <Routes>
                        <Route element={userHasRole(Role.ADMIN) ? <Management/> : <NotAllowed requiredRoles={[Role.ADMIN]}/>} path="/management"/>
                        <Route element={userHasRole(Role.ADMIN) ? <ManagementUsers/> : <NotAllowed requiredRoles={[Role.ADMIN]}/>} path="/management-users"/>
                        <Route element={userHasRole(Role.ADMIN) ? <ManagementHotels/> : <NotAllowed requiredRoles={[Role.ADMIN]}/>} path="/management-hotels"/>
                        <Route element={(userHasRole(Role.TRANSACTION_DEFAULT) || userHasRole(Role.TRANSACTION_GLOBAL)) ? <TransactionHistory/> : <NotAllowed requiredRoles={[Role.TRANSACTION_GLOBAL, Role.TRANSACTION_DEFAULT]}/>} path="/transaction-history"/>
                        <Route element={userHasRole(Role.SUPERVISOR) ? <Supervising/> : <NotAllowed requiredRoles={[Role.SUPERVISOR]}/>} path="/supervising"/>


                        <Route element={userHasRole(Role.BEDS24_INFO) ? <Dropdown/> : <NotAllowed requiredRoles={[Role.BEDS24_INFO]}/>} path="/drop-down"/>
                        <Route element={userHasRole(Role.BEDS24_INFO) ? <InternalLogsPage/> : <NotAllowed requiredRoles={[Role.BEDS24_INFO]}/>} path="/internal-logs"/>
                        <Route element={userHasRole(Role.BEDS24_INFO) ? <InternalNotesPage/> : <NotAllowed requiredRoles={[Role.BEDS24_INFO]}/>} path="/internal-notes"/>
                        <Route element={userHasRole(Role.BEDS24_INFO) ? <LowInventoryScanner/> : <NotAllowed requiredRoles={[Role.BEDS24_INFO]}/>} path="/low-inventory-scanner"/>

                        {sseLoginEvent && <Route element={userHasRole(Role.CHAT) ? <Chat sseLoginEvent={sseLoginEvent}/> : <NotAllowed requiredRoles={[Role.CHAT]}/>} path="/chat"/>}



                        <Route element={(userHasRole(Role.SUPERVISOR) || userHasRole(Role.BEDS24_INFO)) ? <LogsPage/> : <NotAllowed requiredRoles={[Role.SUPERVISOR, Role.BEDS24_INFO]}/>} path="/logs"/>

                        <Route element={userHasRole(Role.BEDS24_INFO) ? <ReviewsHotelPage/> : <NotAllowed requiredRoles={[Role.BEDS24_INFO]}/>} path="/reviews-hotel"/>
                        <Route element={userHasRole(Role.BEDS24_INFO) ? <ReviewsHotelPublicPage/> : <NotAllowed requiredRoles={[Role.BEDS24_INFO]}/>} path="/reviews-hotel-public"/>
                        <Route element={userHasRole(Role.BEDS24_INFO) ? <TransactionLogsPage/> : <NotAllowed requiredRoles={[Role.BEDS24_INFO]}/>} path="/transaction-logs"/>

                        <Route element={userHasRole(Role.SITEMINDER) ? <SiteminderHistoryPage/> : <NotAllowed requiredRoles={[Role.SITEMINDER]}/>} path="/siteminder-history"/>
                        <Route element={userHasRole(Role.SITEMINDER) ? <SiteminderReceiverPage/> : <NotAllowed requiredRoles={[Role.SITEMINDER]}/>} path="/siteminder-receiver"/>
                        <Route element={userHasRole(Role.SITEMINDER) ? <SiteminderReceiverHistoryPage/> : <NotAllowed requiredRoles={[Role.SITEMINDER]}/>} path="/siteminder-receiver-history"/>
                        <Route element={userHasRole(Role.SITEMINDER) ? <SiteminderPage/> : <NotAllowed requiredRoles={[Role.SITEMINDER]}/>} path="/siteminder"/>
                        <Route element={userHasRole(Role.SITEMINDER) ? <HotelsSpreadsheetPage/> : <NotAllowed requiredRoles={[Role.SITEMINDER]}/>} path="/hotels-spreadsheet"/>
                        <Route element={userHasRole(Role.SITEMINDER) ? <SiteminderBookingCancellationPage/> : <NotAllowed requiredRoles={[Role.SITEMINDER]}/>} path="/siteminder-cancellation"/>

                        <Route element={userHasRole(Role.CANCELLATION) ? <CancellationPage/> : <NotAllowed requiredRoles={[Role.CANCELLATION]}/>} path="/cancellation"/>

                        <Route element={(userHasRole(Role.CLOUDBEDS_RESTRICTED) || userHasRole(Role.CLOUDBEDS_MASTER)) ? <CloudbedsCalendarPage/> : <NotAllowed requiredRoles={[Role.CLOUDBEDS_RESTRICTED, Role.CLOUDBEDS_MASTER]}/>} path="/cloudbeds-calendar"/>
                        <Route element={(userHasRole(Role.CLOUDBEDS_RESTRICTED) || userHasRole(Role.CLOUDBEDS_MASTER)) ? <CloudbedsBookingsPage/> : <NotAllowed requiredRoles={[Role.CLOUDBEDS_RESTRICTED, Role.CLOUDBEDS_MASTER]}/>} path="/cloudbeds-bookings"/>
                        <Route element={(userHasRole(Role.ADMIN)  || userHasRole(Role.CLOUDBEDS_LOGS))? <ModifyCalendarLogsPage/> : <NotAllowed requiredRoles={[Role.ADMIN]}/>} path="/cloudbeds-calendar-logs"/>

                        <Route element={userHasRole(Role.SITEMINDER) ? <DerbysoftHotelsSpreadsheetPage/> : <NotAllowed requiredRoles={[Role.SITEMINDER]}/>} path="/derbysoft-hotels-spreadsheet"/>
                        <Route element={userHasRole(Role.SITEMINDER) ? <DerbysoftHistoryPage/> : <NotAllowed requiredRoles={[Role.SITEMINDER]}/>} path="/derbysoft-history"/>
                        <Route element={userHasRole(Role.SITEMINDER) ? <DerbysoftReceiverPage/> : <NotAllowed requiredRoles={[Role.SITEMINDER]}/>} path="/derbysoft-receiver"/>
                        <Route element={userHasRole(Role.SITEMINDER) ? <DerbysoftReceiverHistoryPage/> : <NotAllowed requiredRoles={[Role.SITEMINDER]}/>} path="/derbysoft-receiver-history"/>

                        <Route element={userHasRole(Role.ADMIN) ? <CsvLoaderPage/> : <NotAllowed requiredRoles={[Role.ADMIN]}/>} path="/csv-loader"/>
                        <Route element={userHasRole(Role.ADMIN) ? <ExtractBasePricePage/> : <NotAllowed requiredRoles={[Role.ADMIN]}/>} path="/extract-base-price"/>

                        <Route element={<BankingSecurityCodePage/>} path="/banking-security-code"/>

                        <Route element={<Home/>} path="/"/>
                    </Routes>
                </div>

                <CustomSidebar setSidebarVisible={setSidebarVisible} sidebarVisible={sidebarVisible}/>

            </HashRouter>

        <Toast ref={toastRef} position={"bottom-right"}/>
    </div>

}

export default function App() {
    return <GlobalContextProvider>
        <AppInner/>
    </GlobalContextProvider>
};