import {Hotel, HotelTransferDate, TransferDateType} from "../../../data/BackendClasses";
import {Dialog} from "primereact/dialog";
import {formHasErrors, isNumber} from "../../../lib/helpers";
import React, {useContext, useState} from "react";
import {useFormState} from "../../../data/Form";
import {postJsonToBackend} from "../../../data/network";
import {GlobalContext} from "../../../data/GlobalContext";
import {isReturnedErrors} from "../../../components/ErrorModal";
import {Calendar} from "primereact/calendar";


export default function EditHotelDialog(
    {
        showEditDialog,
        selectedHotel,
        onClose
    }:{
        showEditDialog: boolean,
        selectedHotel: Hotel,
        onClose: () => void
    }) {
    const {inputValues, handleInputChange, dirty} = useFormState({
        ...selectedHotel,
        profile: selectedHotel.profile ? selectedHotel.profile : '',
        propertyId: selectedHotel.propertyId ? selectedHotel.propertyId : '',
        category: selectedHotel.category ? selectedHotel.category : '',
        tax: selectedHotel.tax.toString(),
        taxPercent: selectedHotel.taxPercent.toString(),
        taxPerNight: selectedHotel.taxPerNight.toString(),
        taxPerNightPercent: selectedHotel.taxPerNightPercent.toString(),
        airbnbCom: selectedHotel.airbnbCom.toString(),
        rocketstayShare: selectedHotel.rocketstayShare.toString(),
        resortFee: selectedHotel.resortFee.toString(),
        resortFeePercent: selectedHotel.resortFeePercent.toString(),
        passThrough: selectedHotel.passThrough.toString(),
        lodgingTaxDaily: selectedHotel.lodgingTaxDaily.toString(),
        transferToNewFee: selectedHotel.transferToNewFee,
        endTransferToNewFee: selectedHotel.endTransferToNewFee
    })

    const erori: { [x: string]: string[] } = {
        hotelName: [],
        hotelProfile: [],
        propertyId: [],
        category: [],
        tax: [],
        taxPercent: [],
        taxPerNight: [],
        taxPerNightPercent: [],
        airbnbCom: [],
        rocketstayShare: [],
        resortFee: [],
        resortFeePercent: [],
        passThrough: [],
        lodgingTaxDaily: [],
        transferToNewFee: [],
        endTransferToNewFee: [],
        initialTransferToRsFee: [],
        initialTransferToManagementFee: []
    };
    const {showToastMessage} = useContext(GlobalContext);
    const [transferToNewFee, setTransferToNewFee] = useState<string | Date | Date[] | undefined | null>(selectedHotel.transferToNewFee);
    const [endTransferToNewFee, setEndTransferToNewFee] = useState<string | Date | Date[] | undefined | null>(selectedHotel.endTransferToNewFee);
    const [dataDirty, setDataDirty] = useState<boolean>(false);

    const [initialTransferToRsFee, setInitialTransferToRsFee] = useState<string | Date | Date[] | undefined | null>(undefined);
    const [initialTransferToManagementFee, setInitialTransferToManagementFee] = useState<string | Date | Date[] | undefined | null>(undefined);

    const [transferDatesChanged, setTransferDatesChanged] = useState<boolean>(false);

    const [transferDates, setTransferDates] = useState<HotelTransferDate[]>(selectedHotel.transferDates);

    const [newHotelTransferDate, setNewHotelTransferDate] = useState<HotelTransferDate>({
        id: 0,
        start_date: undefined,
        end_date: undefined,
        transferDateType: transferDates.length > 0 ? transferDates[transferDates.length-1].transferDateType == TransferDateType.RS_FEE ? TransferDateType.MANAGEMENT_FEE : TransferDateType.RS_FEE : TransferDateType.RS_FEE,
        hotelId: selectedHotel.id
    });

    function editHotel(e: any){
        e.preventDefault();

        console.log("MODIFIED HOTEL ", getModifiedHotel());

        postJsonToBackend(
            "/hotels/edit",
            // {
            //     ...inputValues,
            //     transferToNewFee: transferToNewFee,
            //     endTransferToNewFee: endTransferToNewFee
            // }
            getModifiedHotel()
        )
            .then(rsp => {
                showToastMessage("Success", "Hotel modified", "success");
                onClose();
            })
            .catch(err => {
                console.log("ERR ", err);
                if(isReturnedErrors(err)){
                    showToastMessage("Error", err.errors[0].message, "error");
                } else {
                    showToastMessage("Error", "Something went bad and the hotel was not modified", "error");
                }
            })
    }

    function getModifiedHotel() : any {
        if (selectedHotel.transferDates.length == 0)
            return {
                ...inputValues,
                transferToNewFee: transferToNewFee,
                endTransferToNewFee: endTransferToNewFee,
                transferDates : [
                    {
                        id: null,
                        start_date: initialTransferToRsFee != undefined ? initialTransferToRsFee : initialTransferToManagementFee,
                        end_date: null,
                        transferDateType: initialTransferToRsFee != undefined ? TransferDateType.RS_FEE : TransferDateType.MANAGEMENT_FEE,
                        hotelId: selectedHotel.id
                    }
                ]
            }
        else {
            return {
                ...inputValues,
                transferToNewFee: transferToNewFee,
                endTransferToNewFee: endTransferToNewFee,
                transferDates: newHotelTransferDate.start_date != undefined ? [...transferDates, newHotelTransferDate] : transferDates
            }
        }
    }

    function hotelIsModified(){
        return inputValues.name != selectedHotel.name ||
            inputValues.profile != selectedHotel.profile ||
            inputValues.propertyId != selectedHotel.propertyId ||
            inputValues.category != selectedHotel.category ||
            inputValues.taxPercent != selectedHotel.taxPercent.toString() ||
            inputValues.airbnbCom != selectedHotel.airbnbCom.toString() ||
            inputValues.rocketstayShare != selectedHotel.rocketstayShare.toString() ||
            transferToNewFee != selectedHotel.transferToNewFee ||
            endTransferToNewFee != selectedHotel.endTransferToNewFee ||
            initialTransferToRsFee != undefined ||
            initialTransferToManagementFee != undefined
    }

    function checkForFormErrors() {
        if (!inputValues.name && (dirty || dataDirty)) {
            erori.hotelName.push("Hotel name is mandatory");
        }

        if(inputValues.propertyId && !/^\d+$/.test(inputValues.propertyId)){
            erori.propertyId.push("Property Id should be a number");
        }

        if((dirty || dataDirty) && !(inputValues.propertyId.length > 0 || inputValues.profile.length > 0)){
            erori.propertyId.push("At least one of this fields is mandatory");
            erori.hotelProfile.push("At least one of this fields is mandatory");
        }

        if((dirty || dataDirty) && (inputValues.category != '') && !isNumber(inputValues.category)){
            erori.category.push("Category should be a numeric value");
        }

        if((dirty || dataDirty) && (inputValues.taxPercent != '') && !isNumber(inputValues.taxPercent)){
            erori.taxPercent.push("Tax Percent should be a numeric value");
        }

        if((dirty || dataDirty) && (inputValues.airbnbCom != '') && !isNumber(inputValues.airbnbCom)){
            erori.airbnbCom.push("Airbnb Com should be a numeric value");
        }

        if((dirty || dataDirty) && (inputValues.rocketstayShare != '') && !isNumber(inputValues.rocketstayShare)){
            erori.rocketstayShare.push("Rocketstay Share should be a numeric value");
        }
    }

    checkForFormErrors();

    function minTransferFeeDate() : Date | undefined {
        if(transferToNewFee == undefined && endTransferToNewFee == undefined)
            return undefined;

        if(transferToNewFee == undefined && endTransferToNewFee != undefined)
            return new Date(selectedHotel.endTransferToNewFee);

        if(endTransferToNewFee == undefined && transferToNewFee != undefined){
            return new Date(selectedHotel.transferToNewFee);
        }

        return new Date(selectedHotel.transferToNewFee).getTime() > new Date(selectedHotel.endTransferToNewFee).getTime() ? new Date(selectedHotel.transferToNewFee) : new Date(selectedHotel.endTransferToNewFee);

    }

    return <>
        <Dialog
            header={`Edit ${selectedHotel.name}`}
            visible={showEditDialog}
            style={{ width: '50vw'}}
            onHide={onClose}
            pt={{
                root: {className: "!shadow-2xl"},
                content: {className: "!bg-white/70  backdrop-blur-3xl"},
                header: {className: "!bg-white/70  backdrop-blur-3xl !text-[#644243]"},
                headerIcons: {className: "!text-white "},
                closeButton: {className: "!text-white "}
            }}
        >
            <div>
                <form onSubmit={editHotel}>
                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.hotelName.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="name">Full Name</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.hotelName.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="name" id="name" type="text" value={inputValues.name} onChange={handleInputChange}/>
                        {erori.hotelName.length > 0 && <div className="text-red-500 text-sm">{erori.hotelName[erori.hotelName.length-1]}</div>}
                    </div>
                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.hotelProfile.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="profile">Hotel Profile</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.hotelProfile.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="profile" id="profile" type="text" value={inputValues.profile} onChange={handleInputChange}/>
                        {erori.hotelProfile.length > 0 && <div className="text-red-500 text-sm">{erori.hotelProfile[erori.hotelProfile.length-1]}</div>}
                    </div>

                    <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.propertyId.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="propertyId">Property ID</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.propertyId.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="propertyId" id="propertyId" type="text" value={inputValues.propertyId} onChange={handleInputChange}/>
                            {erori.propertyId.length > 0 && <div className="text-red-500 text-sm">{erori.propertyId[erori.propertyId.length-1]}</div>}
                        </div>
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.category.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="category">Category</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.category.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="category" id="category" type="text" value={inputValues.category} onChange={handleInputChange}/>
                            {erori.category.length > 0 && <div className="text-red-500 text-sm">{erori.category[erori.category.length-1]}</div>}
                        </div>
                    </div>

                    <div className="flex flex-row gap-2">
                        {/*<div className="mb-2 w-1/2">*/}
                        {/*    <label className={`block mb-2 font-medium ${erori.tax.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="tax">Tax</label>*/}
                        {/*    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.tax.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="tax" id="tax" type="text" value={inputValues.tax} onChange={handleInputChange}/>*/}
                        {/*    {erori.tax.length > 0 && <div className="text-red-500 text-sm">{erori.tax[erori.tax.length-1]}</div>}*/}
                        {/*</div>*/}
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.taxPercent.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="taxPercent">Tax %</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.taxPercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="taxPercent" id="taxPercent" type="text" value={inputValues.taxPercent} onChange={handleInputChange}/>
                            {erori.taxPercent.length > 0 && <div className="text-red-500 text-sm">{erori.taxPercent[erori.taxPercent.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.taxPerNight.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="taxPerNight">Hotel Tax/Night</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.taxPerNight.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="taxPerNight" id="taxPerNight" type="text" value={inputValues.taxPerNight} onChange={handleInputChange}/>
                            {erori.taxPerNight.length > 0 && <div className="text-red-500 text-sm">{erori.taxPerNight[erori.taxPerNight.length-1]}</div>}
                        </div>
                    </div>

                    {/*<div className="flex flex-row gap-2">*/}
                    {/*    */}
                    {/*    <div className="mb-2 w-1/2">*/}
                    {/*        <label className={`block mb-2 font-medium ${erori.taxPerNightPercent.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="taxPerNightPercent">Tax/Night %</label>*/}
                    {/*        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.taxPerNightPercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="taxPerNightPercent" id="taxPerNightPercent" type="text" value={inputValues.taxPerNightPercent} onChange={handleInputChange}/>*/}
                    {/*        {erori.taxPerNightPercent.length > 0 && <div className="text-red-500 text-sm">{erori.taxPerNightPercent[erori.taxPerNightPercent.length-1]}</div>}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.airbnbCom.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="airbnbCom">Airbnb Com %</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.airbnbCom.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="airbnbCom" id="airbnbCom" type="text" value={inputValues.airbnbCom} onChange={handleInputChange}/>
                            {erori.airbnbCom.length > 0 && <div className="text-red-500 text-sm">{erori.airbnbCom[erori.airbnbCom.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.rocketstayShare.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="rocketstayShare">Rocketstay Share %</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.rocketstayShare.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="rocketstayShare" id="rocketstayShare" type="text" value={inputValues.rocketstayShare} onChange={handleInputChange}/>
                            {erori.rocketstayShare.length > 0 && <div className="text-red-500 text-sm">{erori.rocketstayShare[erori.rocketstayShare.length-1]}</div>}
                        </div>
                    </div>

                    {/*<div className="flex flex-row gap-2">*/}
                    {/*    <div className="mb-2 w-1/2">*/}
                    {/*        <label className={`block mb-2 font-medium ${erori.resortFee.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="resortFee">Resort Fee</label>*/}
                    {/*        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.resortFee.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="resortFee" id="resortFee" type="text" value={inputValues.resortFee} onChange={handleInputChange}/>*/}
                    {/*        {erori.resortFee.length > 0 && <div className="text-red-500 text-sm">{erori.resortFee[erori.resortFee.length-1]}</div>}*/}
                    {/*    </div>*/}

                    {/*    <div className="mb-2 w-1/2">*/}
                    {/*        <label className={`block mb-2 font-medium ${erori.resortFeePercent.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="resortFeePercent">Resort Fee %</label>*/}
                    {/*        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.resortFeePercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="resortFeePercent" id="resortFeePercent" type="text" value={inputValues.resortFeePercent} onChange={handleInputChange}/>*/}
                    {/*        {erori.resortFeePercent.length > 0 && <div className="text-red-500 text-sm">{erori.resortFeePercent[erori.resortFeePercent.length-1]}</div>}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.passThrough.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="passThrough">Pass Through % (rocket fee)</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.passThrough.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="passThrough" id="passThrough" type="text" value={inputValues.passThrough} onChange={handleInputChange}/>
                            {erori.passThrough.length > 0 && <div className="text-red-500 text-sm">{erori.passThrough[erori.passThrough.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.lodgingTaxDaily.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="lodgingTaxDaily">Lodging Tax Daily</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.resortFeePercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="lodgingTaxDaily" id="lodgingTaxDaily" type="text" value={inputValues.lodgingTaxDaily} onChange={handleInputChange}/>
                            {erori.lodgingTaxDaily.length > 0 && <div className="text-red-500 text-sm">{erori.lodgingTaxDaily[erori.lodgingTaxDaily.length-1]}</div>}
                        </div>
                    </div>

                    <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.transferToNewFee.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="transferToNewFee">Transfer to new fee</label>
                            <Calendar
                                name="transferToNewFee"
                                value={selectedHotel.transferToNewFee ? new Date(selectedHotel.transferToNewFee) : undefined}
                                onChange={
                                    (e) => {
                                        setTransferToNewFee(prevState => e.value)
                                        setDataDirty(prevState => true);
                                    }
                                }
                                pt={{root: {className: `w-full ${erori.transferToNewFee.length > 0 ? "border border-red-500 rounded-md" : ""}`}}}
                                view="date"
                            />
                            {erori.transferToNewFee.length > 0 && <div className="text-red-500 text-sm">{erori.transferToNewFee[erori.transferToNewFee.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.endTransferToNewFee.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="endTransferToNewFee">End transfer to new fee</label>
                            <Calendar
                                name="endTransferToNewFee"
                                value={selectedHotel.endTransferToNewFee ? new Date(selectedHotel.endTransferToNewFee) : undefined}
                                onChange={
                                    (e) => {
                                        setEndTransferToNewFee(prevState => e.value)
                                        setDataDirty(prevState => true);
                                    }
                                }
                                pt={{root: {className: `w-full ${erori.endTransferToNewFee.length > 0 ? "border border-red-500 rounded-md" : ""}`}}}
                                view="date"
                            />
                            {erori.endTransferToNewFee.length > 0 && <div className="text-red-500 text-sm">{erori.endTransferToNewFee[erori.endTransferToNewFee.length-1]}</div>}
                        </div>
                    </div>

                    {selectedHotel.transferDates.length == 0 && <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.initialTransferToRsFee.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="initialTransferToRsFee">Transfer to RS fee</label>
                            <Calendar
                                name="initialTransferToRsFee"
                                value={initialTransferToRsFee}
                                onChange={
                                    (e) => {
                                        setInitialTransferToManagementFee(prevState => undefined);
                                        setInitialTransferToRsFee(prevState => e.value);
                                        setDataDirty(prevState => true);
                                    }
                                }
                                minDate={minTransferFeeDate()}
                                pt={{root: {className: `w-full ${erori.initialTransferToRsFee.length > 0 ? "border border-red-500 rounded-md" : ""}`}}}
                                view="date"
                            />
                            {erori.initialTransferToRsFee.length > 0 && <div className="text-red-500 text-sm">{erori.initialTransferToRsFee[erori.initialTransferToRsFee.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.initialTransferToManagementFee.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="initialTransferToManagementFee">Transfer to Management fee</label>
                            <Calendar
                                name="initialTransferToManagementFee"
                                value={initialTransferToManagementFee}
                                onChange={
                                    (e) => {
                                        setInitialTransferToRsFee(prevState => undefined)
                                        setInitialTransferToManagementFee(prevState => e.value)
                                        setDataDirty(prevState => true);
                                    }
                                }
                                minDate={minTransferFeeDate()}
                                pt={{root: {className: `w-full ${erori.initialTransferToManagementFee.length > 0 ? "border border-red-500 rounded-md" : ""}`}}}
                                view="date"
                            />
                            {erori.initialTransferToManagementFee.length > 0 && <div className="text-red-500 text-sm">{erori.initialTransferToManagementFee[erori.initialTransferToManagementFee.length-1]}</div>}
                        </div>
                    </div>}

                    {transferDates.length > 0 && transferDates.map( (item, index) => <TransferDate
                        item={item}
                        setTransferDatesChanged={setTransferDatesChanged}
                        setTransferDates={setTransferDates}
                        transferDatesChanged
                        shouldBeDisabled={selectedHotel.transferDates[index].end_date != undefined}
                    />)}

                    {transferDates.length > 0 && transferDates[transferDates.length-1].end_date != undefined && <NewTransferDate
                        lastHotelTransferDate={transferDates[transferDates.length-1]}
                        newHotelTransferDate={newHotelTransferDate}
                        setNewHotelTransferDate={setNewHotelTransferDate}
                        setTransferDatesChanged={setTransferDatesChanged}
                        transferDatesChanged={transferDatesChanged}
                    />}

                    <div className="w-full text-center">
                        {!formHasErrors(erori) && (dirty || dataDirty || transferDatesChanged) && (hotelIsModified() || transferDatesChanged) && <button
                            type="submit"
                            className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 px-2 py-1"
                        >
                            Edit
                        </button>}
                    </div>
                </form>
            </div>
        </Dialog>
    </>
}



function TransferDate({item, setTransferDatesChanged, setTransferDates, transferDatesChanged, shouldBeDisabled}:{item: HotelTransferDate, setTransferDatesChanged: (newState: boolean) => void, setTransferDates: (newState: HotelTransferDate[]) => void, transferDatesChanged: boolean, shouldBeDisabled: boolean}){

    return <>
        <div className="flex flex-row gap-2" key={item.id}>
            <div className="mb-2 w-1/2">
                <label className={`block mb-2 font-medium text-blue-500`} htmlFor="startDate">{`Start transfer to ${item.transferDateType == TransferDateType.RS_FEE ? "RS" : "Management"} fee`}</label>
                <Calendar
                    value={item.start_date ? new Date(item.start_date) : undefined}
                    pt={{root: {className: `w-full`}}}
                    disabled={true}
                />
            </div>

            <div className="mb-2 w-1/2">
                <label className={`block mb-2 font-medium text-blue-500`} htmlFor="endDate">{`End transfer to ${item.transferDateType == TransferDateType.RS_FEE ? "RS" : "Management"} fee`}</label>
                <Calendar
                    value={item.end_date ? new Date(item.end_date) : undefined}
                    pt={{root: {className: `w-full`}}}
                    disabled={shouldBeDisabled}
                    onChange={(event: any) => {
                        // @ts-ignore
                        setTransferDates((prevItems: HotelTransferDate[])=> {
                            return prevItems.map(prevItem => {
                                if (item.id === prevItem.id) {
                                    return { ...item, end_date: new Date(event.value) };
                                } else {
                                    return item;
                                }
                            });
                        });
                        setTransferDatesChanged(true);
                    }}
                    minDate={ item.start_date ? new Date(item.start_date) : undefined}
                />
            </div>
        </div>

    </>
}

function NewTransferDate({
     lastHotelTransferDate,
     newHotelTransferDate,
     setNewHotelTransferDate,
     setTransferDatesChanged,
     transferDatesChanged
}:{
    lastHotelTransferDate: HotelTransferDate,
    newHotelTransferDate: HotelTransferDate,
    setNewHotelTransferDate: (item: HotelTransferDate) => void,
    setTransferDatesChanged: (newState: boolean) => void,
    transferDatesChanged: boolean
}){
    return <>
        <div className="flex flex-row gap-2" key={newHotelTransferDate.id}>
            <div className="mb-2 w-1/2">
                <label className={`block mb-2 font-medium text-blue-500`} htmlFor="startDate">{`Start transfer to ${newHotelTransferDate.transferDateType == TransferDateType.RS_FEE ? "RS" : "Management"} fee`}</label>
                <Calendar
                    value={newHotelTransferDate.start_date ? new Date(newHotelTransferDate.start_date) : undefined}
                    pt={{root: {className: `w-full`}}}
                    minDate={lastHotelTransferDate.end_date ? new Date(lastHotelTransferDate.end_date) : undefined}
                    onChange={(event: any) => {
                        setNewHotelTransferDate({...newHotelTransferDate, start_date: new Date(event.value.toString())})
                        setTransferDatesChanged(true);
                    }}
                />
            </div>

            <div className="mb-2 w-1/2">
                <label className={`block mb-2 font-medium text-blue-500`} htmlFor="endDate">{`End transfer to ${newHotelTransferDate.transferDateType == TransferDateType.RS_FEE ? "RS" : "Management"} fee`}</label>
                <Calendar
                    value={newHotelTransferDate.end_date ? new Date(newHotelTransferDate.end_date) : undefined}
                    pt={{root: {className: `w-full`}}}
                    minDate={newHotelTransferDate.start_date ? new Date(newHotelTransferDate.start_date) : undefined}
                    onChange={(event: any) => {
                        setNewHotelTransferDate({...newHotelTransferDate, end_date: new Date(event.value.toString())})
                        setTransferDatesChanged(true);
                    }}
                    disabled={true}
                />
            </div>
        </div>

    </>
}

