import {classNames} from "primereact/utils";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {DropDown} from "../../../data/BackendClasses";
import {createPaginationParams, getJsonFromBackend} from "../../../data/network";
import {GlobalContext} from "../../../data/GlobalContext";
import {Column} from "primereact/column";
import {Skeleton} from "primereact/skeleton";
import {Dialog} from "primereact/dialog";
import {formatDate} from "../../../lib/helpers";
import {Paginator} from "primereact/paginator";
import {InputNumber, InputNumberValueChangeEvent} from "primereact/inputnumber";
import XMLViewer from "react-xml-viewer";
import ReactJson from "@microlink/react-json-view";


export default function DropDownPage() {

    const tableClassNames = classNames('w-full !rounded-md', {});
    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };
    // @ts-ignore
    const dt = useRef<DataTable>(null);

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [globalFilterValue, setGlobalFilterValue] = useState<number|undefined|null>(undefined);

    const [data, setData] = useState<DropDown[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<any>("");
    const [dialogHeader, setDialogHeader] = useState<string>("");

    const [debouncedValue, setDebouncedValue] = useState<number|undefined|null>(undefined);


    useEffect(() => {

        console.log("DEBOUNCED VALUE ", debouncedValue);
        console.log("DEBOUNCED VALUE ", debouncedValue ? debouncedValue : 0);

        let url = debouncedValue ? ("/drop-down/get" + createPaginationParams(page, pageSize) + "&bookId=" + debouncedValue) : ("/drop-down/get" + createPaginationParams(page, pageSize));

        getJsonFromBackend("/drop-down/get" + createPaginationParams(page, pageSize) + "&bookId=" + (debouncedValue ? debouncedValue : 0))
            .then((rsp) => {
                setTotalElements(rsp.totalElements)
                setData(rsp.content);
                setDataLoaded(true);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting drop down data", "error");
                setDataLoaded(true);
                console.log(err)
            })
    }, [reload, page, pageSize, debouncedValue])

    useEffect(() => {
        console.log("UseEffect globalFilterValue")

        const timeoutId = setTimeout(() => {
            setDebouncedValue(globalFilterValue)
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [globalFilterValue]);


    const onGlobalFilterChange = (e: InputNumberValueChangeEvent) => {
        console.log("ONGLOBALFILTER CHANGE ", e.value)
        setGlobalFilterValue(e.value)
    };

    function onPageChanged(e: any) {
        if(pageSize != e.rows)
            setPageSize(e.rows);
        if(first != e.first){
            setFirst(e.first);
        }
        if(page != e.page){
            setPage(e.page)
        }
    }

    const renderHeader = () => {
        return (
            <div className="flex flex-row justify-between">
                <span className="p-input-icon-left">
                    {/*<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search by booking ID" />*/}
                    <InputNumber value={globalFilterValue} onValueChange={onGlobalFilterChange} placeholder="Search by booking ID" useGrouping={false}/>
                </span>
                <div className="flex align-items-center justify-content-end gap-2">
                    {/*<Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />*/}
                    <button
                        className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 p-2"
                        onClick={() => exportCSV(false)}
                    >
                        <i className="fa-solid fa-file-csv fa-xl"/>
                    </button>

                </div>
            </div>
        );
    };

    const exportCSV = (selectionOnly: boolean) => {
        console.log("EXPORT CSV ", dt);
        console.log(dt.current);
        dt.current.exportCSV({ selectionOnly });
    };

    function renderBeforeData(data: DropDown){
        return <div
            className="max-w-sm overflow-hidden overflow-ellipsis hover:cursor-pointer"
            // onClick={() => showCustomDialog(`Before (${data.bookId})`, data.before)}
            onClick={() => {
                try {
                    showCustomDialog(
                        `Before (${data.bookId})`,
                        <ReactJson src={JSON.parse(data.before.replace(/'/g, '"').replaceAll("True", "true").replaceAll("False", "false"))}/>
                    )
                } catch (err){
                    showCustomDialog(
                        `Before (${data.bookId})`,
                        data.before
                    )
                }
            }}

        >
            <span className="whitespace-nowrap">{data.before}</span>
        </div>
    }

    function renderAfterData(data: DropDown){
        return <div
            className="max-w-sm overflow-hidden overflow-ellipsis hover:cursor-pointer"
            // onClick={() => showCustomDialog(`After (${data.bookId})`, data.after)}
            onClick={() => {
                try {
                    showCustomDialog(
                        `After (${data.bookId})`,
                        <ReactJson src={JSON.parse(data.after.replace(/'/g, '"').replaceAll("True", "true").replaceAll("False", "false"))}/>
                    )
                } catch (err){
                    showCustomDialog(
                        `After (${data.after})`,
                        data.after
                    )
                }
            }}
        >
            <span className="whitespace-nowrap">{data.after}</span>
        </div>
    }

    function showCustomDialog(header: string, content: any){
        setDialogContent(content);
        setDialogHeader(header);
        setShowDialog(true);
    }

    function hideDialog(){
        setDialogContent("");
        setDialogHeader("");
        setShowDialog(false);
    }

    function renderDateColumn(data: DropDown){
        return data.inserted ? formatDate(data.inserted) : "-";
    }

    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="drop-down-page">
        <div className="flex flex-col h-fit !rounded-lg bg-white p-1 grow">
            {dataLoaded ? <>
                <DataTable
                    ref={dt}
                    value={data}
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    stripedRows
                    emptyMessage= "No data found."
                    tableStyle={{ minWidth: '50rem' }}
                    rowClassName={rowClass}
                    className={tableClassNames}
                    pt={{
                        header: {className: "!bg-white !border-0 !rounded-t-md "}
                    }}
                    header= {renderHeader()}
                >
                    <Column className="!bg-white " field="bookId" header="Booking ID" pt={{headerTitle: {className: "!font-bold"}}}/>
                    <Column className="!bg-white " field="before" header="Before" body={renderBeforeData} pt={{headerTitle: {className: "!font-bold"}}}/>
                    <Column className="!bg-white " field="after" header="After" body={renderAfterData} pt={{headerTitle: {className: "!font-bold"}}}/>
                    <Column className="!bg-white " field="inserted" header="Date" body={renderDateColumn} pt={{headerTitle: {className: "!font-bold"}}}/>
                </DataTable>

                <Paginator
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    onPageChange={onPageChanged}
                    template="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    pt={{
                        root: {className: "!bg-white"},
                    }}
                />
            </> : <Skeleton className="h-72"/>}
        </div>


        <Dialog header={dialogHeader} visible={showDialog} style={{ width: '50vw' }} onHide={hideDialog}>
            <p className="m-0">
                {dialogContent}
            </p>
        </Dialog>

    </div>

}