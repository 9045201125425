import "./ManagementHotels.scss";
import {Hotel} from "../../../data/BackendClasses";
import React, {useContext, useEffect, useState} from "react";
import {getJsonFromBackend, postJsonToBackend} from "../../../data/network";
import AddHotelComponent from "./AddHotelComponent";
import {DataTable, DataTableFilterMeta} from "primereact/datatable";
import {Column} from "primereact/column";
import {classNames} from "primereact/utils";
import {GlobalContext} from "../../../data/GlobalContext";
import {FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import EditHotelDialog from "./EditHotelDialog";
import HotelDetailsDialog from "./HotelDetailsDialog";


export default function ManagementHotels() {

    const [hotels, setHotels] = useState<Hotel[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const [showAddComponent, setShowAddComponent] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [selectedHotel, setSelectedHotel] = useState<Hotel | undefined>(undefined);

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [filters, setFilters] = useState<DataTableFilterMeta>({global: { value: null, matchMode: FilterMatchMode.CONTAINS}});
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);

    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };
    const tableClassNames = classNames('w-full !rounded-md', {});


    useEffect(() => {
        setLoading(prevState => true);
        getJsonFromBackend("/hotels/get")
            .then((rsp: Hotel[]) => {
                setHotels(rsp);
                setLoading(false);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting the hotels data", "error");
                console.log(err)
                setLoading(false);
            })
    }, [reload])


    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-row justify-content-end">
                <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Name/property_id/profile" />
                </span>
            </div>
        );
    };

    function generateActionButtons(hotel: Hotel) {
        return  <div className="flex w-full text-center gap-2">
            <button
                className="border border-transparent font-medium rounded-md text-emerald-700 bg-emerald-100 hover:bg-emerald-200 px-2"
                onClick={() => {
                    console.log("HOTEL ", hotel)
                    setSelectedHotel(prevState => hotel);
                    setShowEditDialog(prevState => true);
                    setShowDetailsDialog(prevState => false);
                }}
            >
                <i className="fa-solid fa-pen-to-square"/>
                <span className="ml-1">Edit</span>
            </button>

            <button
                className="border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 px-2"
                onClick={() => {
                    removeHotel(hotel);
                }}
            >
                <i className="fa-solid fa-trash"/>
                <span className="ml-1">Remove</span>
            </button>

        </div>
    }

    function renderShowDetailsButton(hotel: Hotel){
        return <button
            className="border border-transparent font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 px-2"
            onClick={() => {
                setSelectedHotel(prevState => hotel);
                setShowDetailsDialog(prevState => true);
                setShowEditDialog(prevState => false);
            }}
        >
            <span className="ml-1">Details</span>
        </button>
    }

    async function removeHotel(hotel: Hotel){
        if(!window.confirm(`Are you sure you want to remove ${hotel.name}?`)){
            return;
        }
        postJsonToBackend("/hotels/remove", hotel)
            .then(rsp => {
                showToastMessage("Success", "Hotel removed", "success");
                setReload((reload) => !reload);
            })
            .catch((err) => {
                console.log(err);
                showToastMessage("Error", "Something bad happened", "error");
            })
    }


    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="management-hotels-page">

        <div className="flex flex-col h-fit !rounded-lg bg-white/70 p-1 grow">
            <DataTable
                stripedRows= {true}
                filters= {filters}
                value= {hotels}
                paginator
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                rowsPerPageOptions={[5, 10, 25, 50]}
                first={first}
                rows={pageSize}
                totalRecords={hotels.length}
                tableStyle= {{ minWidth: '50rem'}}
                rowClassName= {rowClass}
                className= {tableClassNames}
                globalFilterFields= {['name', 'profile', 'propertyId']}
                header= {renderHeader()}
                emptyMessage= "No hotels found."
                loading= {loading}
                pt={{
                    header: {className: "!bg-white/70 !border-0 !rounded-t-md "},
                    paginator: {
                        root: {className: "!bg-white/70"}
                    }
                }}
            >

                <Column className="!bg-white/70 " sortable field="name" header="Hotel Name"/>
                <Column className="!bg-white/70 " sortable field="profile" header="Hotel Profile"/>
                <Column className="!bg-white/70 " sortable field="propertyId" header="Property ID"/>
                <Column className="!bg-white/70 " sortable field="category" header="Category"/>
                {/*<Column className="!bg-white/70 " sortable field="taxPercent" header="Tax Percent"/>*/}
                {/*<Column className="!bg-white/70 " sortable field="airbnbCom" header="Airbnb Com"/>*/}
                {/*<Column className="!bg-white/70 " sortable field="rocketstayShare" header="Rocketstay Share"/>*/}

                <Column className="!bg-white/70 " body={renderShowDetailsButton} header="Details"/>
                <Column className="!bg-white/70 " body={generateActionButtons} header="Action"/>
            </DataTable>

        </div>

        <div className={`${showAddComponent ? "hidden" : ""}`}>
            <i
                className="fa-solid fa-plus fa-2x mx-2 text-green-900 hover:text-green-700 cursor-pointer"
                onClick={() => setShowAddComponent(true)}
            />
        </div>

        <div className={`flex flex-col w-1/4 gap-2 transition-all duration-500 ${showAddComponent ? "" : "hidden"}`}>
            <AddHotelComponent afterAdd={() => {setReload(!reload); setLoading(true)}} hideComponent={() => setShowAddComponent(false)}/>
        </div>

        {selectedHotel && showEditDialog && <EditHotelDialog
            showEditDialog={showEditDialog}
            selectedHotel={selectedHotel}
            onClose={() => {
                setSelectedHotel(undefined);
                setShowEditDialog(false);
                setReload(!reload);
            }}
        />}

        {selectedHotel && showDetailsDialog && <HotelDetailsDialog
            showDetailsDialog={showDetailsDialog}
            selectedHotel={selectedHotel}
            onClose={() => {
                setSelectedHotel(prevState => undefined);
                setShowDetailsDialog(prevState => false);
            }}
        />}
    </div>

}